/* eslint { import/no-anonymous-default-export: 0 } */

import app from './appConfig';
import network from './networkConfig';
import translations from './translations';
import { GNBOverrides as theme } from '@verosource/myhealth-web/src/themes'

export default {
  app,
  network,
  theme,
  translations,
};

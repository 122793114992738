/**
 * @file Configuration overrides for the client.
 */

/** @type {import('@verosource/myhealth-web/src/config/networkConfig').NetworkConfig} */
const networkConfig = {
  loginProviderType: 'myhealth_broker_dev',
  redirectUri: 'https://myhealth-web.dev.myhealth.gnb-doh.vsfcloud.com/',
};

export default networkConfig;
